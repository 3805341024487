import React,{ Fragment, useState, useEffect, useRef } from "react";
import {Flex,StepLabel, StepContent, Box ,Stack, Text,Step,StepDescription,StepIcon,StepIndicator,StepNumber,StepSeparator,StepStatus,StepTitle,Stepper,useSteps,ChakraProvider, Tab, TabList, TabPanel, TabPanels, Tabs, CSSReset} from '@chakra-ui/react';
import { render } from 'react-dom';
import { css } from '@emotion/react';

import { motion } from 'framer-motion';
import "./Home.css";
import { useNavigate } from "react-router-dom";

const steps = [
  { title: 'First', 
  description: (
    <div>
      <p class="make">MAKE INFORMED DECISION</p>
      <p class="tech">Our talent engine leverages state-of-the-art Artificial <br/>
      Intelligence technology to empower hiring teams, enabling them <br/>
      to make informed decisions quickly and efficiently. By analyzing <br/>
      vast amounts of data and identifying patterns that may not be <br/>
      visible to the human eye, our platform ensures that you are <br/>
      matched with candidates who are not just qualified, but are the <br/>
      perfect fit for your company's unique needs and culture.</p>
    </div>
  ),
  image: <img src="/images/Informed Decision Making.gif" style={{ width:'100%' }} alt="Informed Decision Making" />,
},

  { title: 'Second', 
    description: (
    <div>
      <p class="make">FIND THE MOST SUITABLE CANDIDATE</p>
      <p class="tech">By harnessing the power of advanced algorithms and machine <br/>
      learning, it offers hiring teams an unparalleled advantage in <br/>
      identifying the most suitable candidates for any role. This <br/>
      sophisticated tool sifts through extensive databases of potential <br/>
      hires, analyzing skills, experience, and potential fit with <br/>
      unprecedented accuracy. It ensures that recruiters are <br/>
      equipped with the insights needed to make strategic decisions, <br/>
      streamlining the hiring process and significantly increasing the <br/>
      likelihood of a successful match.</p>
    </div>
  ),
  image: <img src="/images/Suitable Candidate.gif" style={{ width:'100%' }} alt="Informed Decision Making" />,
},

  { title: 'Third', 
    description: (
    <div>
      <p class="make">COMPARE CANDIDATES</p>
      <p class="tech">Talentin Engine stands out as a comprehensive solution not just <br/>
      for finding the best suitable resources, but also for comparing <br/>
      candidates effectively. By facilitating a comparison based on a <br/>
      multitude of factors, including skill sets, cultural fit, and future <br/>
      growth prospects, our engine streamlines the selection process, <br/>
      enabling recruiters to make decisions that are informed, <br/>
      balanced, and aligned with their organization's long-term goals.</p>
    </div>
  ),
  image: <img src="/images/Compare Candidates.gif" style={{ width:'100%' }} alt="Informed Decision Making" />,
},

  { title: 'Fourth', description: (
    <div>
      <p class="make">DIVERSITY, EQUITY & INCLUSION</p>
      <p class="tech">Our Talentin Engine is equipped with features that specifically <br/>
      target DEI objectives, allowing companies to set and meet their <br/>
      diversity hiring goals effectively. It goes beyond the <br/>
      conventional recruitment process by analyzing the inclusivity of <br/>
      the workplace environment and suggesting improvements, <br/>
      ensuring that candidates from all backgrounds feel welcomed <br/>
      and valued. This proactive stance on diversity not only helps in <br/>
      building a more cohesive and harmonious workplace but also <br/>
      enhances the company’s reputation as an inclusive and <br/>
      forward-thinking employer.</p>
    </div>
  ),
  image: <img src="/images/D, E & I.gif" style={{ width:'100%' }} alt="Informed Decision Making" />,
 },
];

function Home() {

  const [selectedTab, setSelectedTab] = useState(0); // State to track the selected tab index
  const navigate = useNavigate();
  const handleTabClick = (index) => {
    setSelectedTab(index);
  };

  const MotionBox = motion(Box);

  const tabVariants = {
    initial: { opacity: 10, y: 20 },
    animate: { opacity: 1, y: 0 },
    // exit: { opacity: 0, y: 10 }
  };
  
const customTabList = [{ 
  borderBottom: 'none',
  backgroundColor: selectedTab === 0? '#21D6AA' : '#E1FFF8',
  color: selectedTab === 0? 'white' : 'black',
  borderRadius: '5%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  cursor: 'pointer',
  marginRight: '50px',
  marginLeft: '50px',
  fontSize: '15px',
  fontWeight: 'bold',
},

{
borderBottom: 'none',
backgroundColor: selectedTab === 1? '#21D6AA' : '#E1FFF8',
color: selectedTab === 1? 'white' : 'black',
borderRadius: '5%',
display: 'flex',
justifyContent: 'center',
alignItems: 'center',
textAlign: 'center',
cursor: 'pointer',
marginRight: '50px',
fontSize: '15px',
fontWeight: 'bold',
},
{
  borderBottom: 'none',
  backgroundColor: selectedTab === 2? '#21D6AA' : '#E1FFF8',
  color: selectedTab === 2? 'white' : 'black',
  borderRadius: '5%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  cursor: 'pointer',
  marginRight: '50px',
  fontSize: '15px',
  fontWeight: 'bold',
},
];

const customTabListStyles = {
  borderBottom: 'none',
  display: 'flex',
  justifyContent:'center',
};

//stepper
const [activeStep, setActiveStep] = useState(0);

const totalSteps = 4;

// useEffect(() => {
//     const interval = setInterval(() => {
//       setActiveStep((prevStep) => (prevStep + 1) % totalSteps); 
//     }, 10000);
// return () => clearInterval(interval); 
//   }, []); 

const [completedSteps, setCompletedSteps] = useState(null);

const handleStepClick = (index) => {
  setActiveStep(index);
  setCompletedSteps(index);
};

const activeStepText = steps[activeStep].description;

const [hovered, setHovered] = useState(false);

//logo changer while scrolling

const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    function handleScroll() {
      setIsScrolled(window.scrollY > 600);
    }

    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

//button scroll down while scrolling

  const [scrollPosition, setScrollPosition] = useState(0);
  const [shouldMoveImage, setShouldMoveImage] = useState(true);
  const [isLaptopScreen, setIsLaptopScreen] = useState(false);

  useEffect(() => {
    const updateScreenSize = () => {
      const screenWidth = window.innerWidth;
      setIsLaptopScreen(screenWidth >= 1024 && screenWidth <= 4000);
    };

    updateScreenSize();
    window.addEventListener('resize', updateScreenSize);

    return () => {
      window.removeEventListener('resize', updateScreenSize);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      // if (shouldMoveImage && isLaptopScreen) {
      if (shouldMoveImage) {
        setScrollPosition(window.scrollY);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [shouldMoveImage, isLaptopScreen]);

  const handleScrollThreshold = 300; // Adjust the scroll threshold as needed

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > handleScrollThreshold) {
        setShouldMoveImage(false);
      } else {
        setShouldMoveImage(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const imageStyle = isLaptopScreen ? {
    top: `${  scrollPosition ? 370 +  300 * 0.1 : 370 }px`, // Adjust the multiplier to control the speed of the movement
    position: 'fixed',
    transition: 'top 0.3s',
  } : {
    top: `${scrollPosition ? 370 +  300 * 0.1 : 370}px`, // Adjust the multiplier to control the speed of the movement
    position: 'fixed',
    transition: 'top 0.3s',
  };

  const [isVisible, setIsVisible] = useState(false);
  const stepperRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      },
      { threshold: 0.1 } // Adjust threshold as needed
    );

    if (stepperRef.current) {
      observer.observe(stepperRef.current);
    }

    return () => {
      if (stepperRef.current) {
        observer.unobserve(stepperRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (isVisible) {
      const interval = setInterval(() => {
        setActiveStep((prevStep) => (prevStep + 1) % totalSteps);
      }, 10000);

      return () => clearInterval(interval);
    }
  }, [isVisible]);

  return(
   
    <Fragment>
      <div class="boddy">
      <header className={`header ${isScrolled ? "scrolled" : ""}`}>
        <img id="logo" class="logo" src={isScrolled ? "/images/Short Logo.svg" : "/images/Full Logo.svg"} alt="Logo" />
      </header>

      <div class="mainnn">
      <div class="container">
       
        <h1 class="elevate">ELEVATE YOUR TALENT ACQUISITION GAME <br/>WITH AI DRIVEN CANDIDATE MATCHING</h1>

        <p class="based">An AI based SaaS platform for talent matching is aimed to streamline the recruitment process,
          <br/> reduce bias, improve efficiency and help organizations make data driven hiring decisions.
        </p>
  </div>

    <div class="cover-item">
      <div class="get">
      <h2>
          Get <span style={{color:'#21D6AA'}}>real time results </span> using our 
          <br/><text class="underline">AI based SaaS platform</text> for talent 
          <br/> matching and determining the best <br/>
          suited candidates.</h2>
      </div>

<img class="wavy" style= {{marginBottom:'-5px'}} src="/images/Waves.gif"></img>
<div  className="background-images">     
<ChakraProvider>
<CSSReset/>
<div class="stacky">
<h3 class="how">HOW IT HELPS?</h3>
  {/* <Stack style={{flex:1}} direction="row" spacing={0}>
  <div class="root">
    <div>
      <Stepper activeStep={activeStep} size='sm' index={activeStep} gap='0' >
      
  
      {steps.map((step, index) => (

      <Step key={index} gap='0' onClick={() => handleStepClick(index)}>

    <Box
      key={index}
      bg={index <= activeStep ? "#21D6AA" : "white"}
      borderRadius="50%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      w="25px"
      h="25px"
      cursor="pointer"
      border={index <= activeStep ? "2px solid #21D6AA" : "white"}
      boxShadow={index <= activeStep ? "#21D6AA" : "0 0 15px #21D6AA"}
    >
    </Box>

    <StepSeparator style={{ backgroundColor: index < activeStep ? '#21D6AA' : 'white' }}  _horizontal={{ ml: '0' }}></StepSeparator>
  
    </Step>
        ))}
      </Stepper>
      
      <Text>
        <b>{steps[activeStep].description}</b>
      </Text>
    </div>
      
      <div class="imagee">
        <div style={{ flex: 1}}>{steps[activeStep].image}</div>
      </div>
    </div>
  </Stack> */}

  <Stack style={{ flex: 1 }} direction="row" spacing={0}>
      <div className="root" ref={stepperRef}>
        <div>
          <Stepper activeStep={activeStep} size="sm" index={activeStep} gap="0">
            {steps.map((step, index) => (
              <Step key={index} gap="0" onClick={() => handleStepClick(index)}>
                <Box
                  bg={index <= activeStep ? '#21D6AA' : 'white'}
                  borderRadius="50%"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  w="25px"
                  h="25px"
                  cursor="pointer"
                  border={index <= activeStep ? '2px solid #21D6AA' : 'white'}
                  boxShadow={index <= activeStep ? '#21D6AA' : '0 0 15px #21D6AA'}
                />
                <StepSeparator style={{ backgroundColor: index < activeStep ? '#21D6AA' : 'white' }} _horizontal={{ ml: '0' }} />
              </Step>
            ))}
          </Stepper>

          <Text>
            <b>{steps[activeStep].description}</b>
          </Text>
        </div>

        <div className="imagee">
          <div style={{ flex: 1 }}>{steps[activeStep].image}</div>
        </div>
      </div>
    </Stack>
  

<Box className="stakoo" padding="20px">
  <Tabs index={selectedTab} onChange={handleTabClick}>
  <TabList style={customTabListStyles}>
    <Tab className="customtab" onClick={() => handleTabClick(0)} style={customTabList[0]}>1</Tab>
    <Tab className="customtab" onClick={() => handleTabClick(1)} style={customTabList[1]}>2</Tab>
    <Tab className="customtab" onClick={() => handleTabClick(2)} style={customTabList[2]}>3</Tab>
  </TabList>

  <TabPanels>
    <TabPanel>
    <MotionBox
                                className="conteinr"
                                initial="initial"
                                animate="animate"
                                padding="30px"
                                exit="exit"
                                variants={tabVariants}
                                transition={{ duration: 0.9 }}
                              >
                                <Text as="h1" className="smart">SMARTEN-UP YOUR HIRING PROCESS</Text>
                                <Box className="row ">
                                  <Box className="column">
                                    <Text as="h3" className="costt">Cost</Text>
                                    <Text className="underp">Streamlining reduces operational and production expenses by eliminating manhours and optimizing resource allocation, leading to increased financial efficiency.</Text>
                                  </Box>
                                  <Box className="column">
                                    <Text as="h3" className="costt">Time</Text>
                                    <Text className="underp">By simplifying matching processes and improving efficiency, streamlining minimizes time spent on reading and determining matching criteria which accelerate timelines and enhancing productivity.</Text>
                                  </Box>
                                  <Box className="column">
                                    <Text as="h3" className="costt">Accuracy</Text>
                                    <Text className="underp">Streamlining enhances accuracy by standardizing procedures and minimizing the complexity skills, which reduces the likelihood of errors and improves overall quality of outputs.</Text>
                                  </Box>
                                </Box>
                              </MotionBox>
                            </TabPanel>
                            <TabPanel>
                            <MotionBox
                                className="conteinr"
                                initial="initial"
                                animate="animate"
                                padding="30px"
                                exit="exit"
                                variants={tabVariants}
                                transition={{ duration: 0.9 }}
                              >
                                <Text as="h1" className="smart">DIVERSITY, EQUITY AND INCLUSIVITY</Text>
                                <Text className="underp">
                                  Focuses on the recognition of diverse cultural backgrounds with an <br />
                                  understanding and appreciating differences and integrating this <br />
                                  awareness into inclusive practices.
                                </Text>
                              </MotionBox>
                            </TabPanel>
                            <TabPanel>
                              <MotionBox
                                className="conteinr"
                                initial="initial"
                                animate="animate"
                                exit="exit"
                                variants={tabVariants}
                                transition={{ duration: 0.9 }}
                              >
                                <Text as="h1" className="smart">INDUSTRY AGNOSTIC</Text>
                                <Text className="underp">
                                  Our state-of-the-art engine with continuous learning capabilities,<br />
                                  functions across all industry verticals in terms of identifying and <br />
                                  attracting talent in various sectors. It leverages universal recruiting <br />
                                  strategies and a deep understanding of diverse job markets to <br />
                                  determine the best match.
                                </Text>
                              </MotionBox>
            
    </TabPanel>
  </TabPanels>
</Tabs>
</Box>
</div>
</ChakraProvider>
</div>

<div style={imageStyle}  class="buttonimage" onClick={()=>{window.location.replace(process.env.REACT_APP_API_LOGIN_URL)}} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
  <img class="button" src={hovered ? '/images/Button Hover.svg' : '/images/Button_Normal State.svg'}/>
</div>



  <div>
    <img class="gradient" src="/images/Gradient below Button.svg"/>
  </div>
  </div>
  </div>
  </div>
  </Fragment>
    );
}


export default Home
